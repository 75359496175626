import { Wrapper } from "@googlemaps/react-wrapper";
import React, { useState } from "react";
import MapComponent from "../mapComponent/MapComponent";
import cssClasses from "./MapContainer.module.css";
import arrow from './arrow.svg'

type Props = {};

function MapContainer({}: Props) {
    const [showMap, setShowMap] = useState(false);

  return (
    <div className={showMap ? cssClasses.showContainer: cssClasses.hideContainer}>
      <Wrapper
        apiKey={"AIzaSyD1TE3AO2uxwIkTvfvvXezofBNFZ98zau4"}
        libraries={["places"]}
      >
        <MapComponent center={{ lat: 41.124656, lng: -100.770216 }} zoom={4} />
      </Wrapper>
      <div className={cssClasses.handle} onClick={()=>setShowMap(!showMap)}>
        <img src={arrow} height={10} className={showMap ? cssClasses.upArrow: cssClasses.downArrow}/> Map
      </div>
    </div>
  );
}

export default MapContainer;
